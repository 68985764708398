import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Reveal from "react-reveal/Reveal"
import Media from "../components/media"
import ProjectTiles from "../components/project-tiles"
import { ImageText, TeamBlock, PartnerLogo } from "../components/modular-blocks"
import { Helmet } from "react-helmet"

export const projectQuery = graphql`
  query allDatoCmsProject($slug: String) {
    page: datoCmsProject(slug: { eq: $slug }) {
      title
      description
      shortSummary
      featureImage {
        gatsbyImageData
        format
        url(imgixParams: { w: "1200", h: "630", fit: "crop" })
        alt
        video {
          thumbnailUrl(format: gif)
        }
      }
      bedrooms
      completed
      location
      space
      imageGallery {
        title
        format
        height
        width
        alt
        url
        video {
          thumbnailUrl(format: gif)
        }
        gatsbyImageData
      }
      contentBlocks {
        ... on DatoCmsPartnerLogo {
          model {
            apiKey
          }
          partners {
            title
            link
            logo {
              gatsbyImageData(imgixParams: { w: "400", h: "400", fit: "crop" })
            }
          }
        }
        ... on DatoCmsBlockImageText {
          model {
            apiKey
          }
          id
          text
          imageOnLeft

          image {
            gatsbyImageData
            format
            url
            alt
            video {
              thumbnailUrl(format: gif)
            }
          }
        }
      }
    }
  }
`

const SingleProject = ({ data: { page } }) => {
  return (
    <div className="wrapper bg-light ">
      <Layout>
        <Helmet>
          <title defer={false}>{page.title + " by Luxe Haven Homes"}</title>
          <meta name="description" content={page.shortSummary} />
          <meta property="og:title" content={page.title + " by Luxe Haven Homes"} />
          <meta property="og:image" content={page.featureImage.url} />
        </Helmet>
        <div className="project-intro  fluid grid-cols-2 lg:gap-x-20 xl:gap-x-40 py-32">
          <div className="col-span-2">
            <Media asset={page.featureImage} />
          </div>

          {/* INTRO TEXT  –––––––––––––––––––––––––––––––––––––––– */}

          <h1 className="col-span-2 mx-100 sm:text-center ">{page.title}</h1>
          <div
            className="col-span-2 mx-100 text-lead  sm:w-4/5 lg:w-2/3 mx-auto sm:text-center"
            dangerouslySetInnerHTML={{ __html: page.description }}
          />
        </div>

        {/* GALLERY –––––––––––––––––––––––––––––––––––––––– */}

        <div className="gallery fluid grid grid-cols-2 col-span-2">
          {page.imageGallery.map(image => (
            <div className={image.width > image.height ? "col-span-2" : "col-span-2 md:col-span-1"}>
              <Reveal effect="fadeInUp">
                <Media asset={image} />
              </Reveal>
            </div>
          ))}
        </div>
        {page.contentBlocks.map(block => (
          <>
            {block.model.apiKey === "block_image_text" && <ImageText map={block} />}
            {block.model.apiKey === "team_block" && <TeamBlock map={block} />}
            {block.model.apiKey === "partner_logo" && <PartnerLogo map={block} />}
          </>
        ))}
        <ProjectTiles title="Other Builds" />
      </Layout>
    </div>
  )
}

export default SingleProject
